<h1>Der Rechner 🧮 von Robin 👨🏼‍🏫</h1>
<div class="form-group">
  <label for="base">Wie viel war es vorher?</label>
  <input [(ngModel)]="base" type="number" class="form-control form-control-lg" id="base" autofocus />
</div>
<div class="form-group">
  <label for="current">Wie viel ist es jetzt?</label>
  <input [(ngModel)]="current" id="current" type="number" class="form-control form-control-lg" />
</div>
<div *ngIf="base > 0" class="jumbotron">
  <p>Das ist eine Veränderung um</p>
  <h1>{{ (current / base) * 100 - 100 | number:'1.2-3' }} %</h1>
</div>
